import {Menu, MenuButton, MenuItem, MenuList, styled} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {selectProjectSelected, setProjectSelected} from "../../store/features/sessionSlice";
import {useCallback, useEffect, useState} from "react";
import Project from "../../models/project";
import ProjectServices from "../../services/ProjectServices";
import {AiOutlineCaretDown} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/consts/router";

const Content = styled("div", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  }
});

const Card = styled("div", {
  baseStyle: {
    width: "200px",
    height: "50px",
    backgroundColor: "#F5F5F5",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 6,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
    cursor: "pointer",
  }
})

const CardAvatar = styled("div", {
  baseStyle: {
    width: "35px",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    borderRadius: 6,
    marginLeft: 2,
    marginRight: 2,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15px",
    textTransform: "uppercase",
  }
})

const CardTitle = styled("div", {
  baseStyle: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "17px",
    textOverflow: "ellipsis",
    maxLines: 1,
    overflow: "hidden",
    height: "17px",
  }
})

const CardArrow = styled("div", {
  baseStyle: {
    width: "15px",
    height: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 2,
    marginTop: 1,
    color: "#383838",
  }
})

function OverlayProject() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectSelected = useSelector(selectProjectSelected);

  const [initials, setInitials] = useState<string>("");

  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    ProjectServices.getProjects().then((data) => {
      setProjects(data);
    }).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    projects.forEach((project) => {
      if (project.id === projectSelected?.id) {
        dispatch(setProjectSelected(project));
        setInitials(project.name.substring(0, 2));
      }
    })
  }, [projects, projectSelected]);

  useEffect(() => {
    if (projectSelected) {
      setInitials(projectSelected.name.substring(0, 2));
    }
  }, [projectSelected]);

  const handleSelectProject = (project: Project) => {
    navigate(ROUTES.HOME_CHILD);
    dispatch(setProjectSelected(project));
  };

  const content = useCallback(() => {
    if (!projectSelected) {
      return (
        <Content>
          <Card>
            <CardAvatar style={{backgroundColor: "#10A9FD"}}>
              SP
            </CardAvatar>
            <CardTitle>
              Select project
            </CardTitle>
            <CardArrow>
              <AiOutlineCaretDown/>
            </CardArrow>
          </Card>
        </Content>
      )
    }

    return (
      <Content>
        <Card>
          <CardAvatar style={{backgroundColor: `#${projectSelected.color}`}}>
            {initials}
          </CardAvatar>
          <CardTitle>
            {projectSelected.name}
          </CardTitle>
          <CardArrow>
            <AiOutlineCaretDown/>
          </CardArrow>
        </Card>
      </Content>
    )
  }, [projectSelected, initials]);

  return (
    <Content>
      <Menu placement="top">
        <MenuButton style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}>
          {content()}
        </MenuButton>
        <MenuList>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              {projects.map((project) => (
                <MenuItem
                  key={project.id}
                  onClick={() => handleSelectProject(project)}
                >
                  <CardAvatar style={{backgroundColor: `#${project.color}`}}>
                    {project.name.substring(0, 2)}
                  </CardAvatar>
                  <CardTitle>
                    {project.name}
                  </CardTitle>
                </MenuItem>
              ))}
            </>
          )}
        </MenuList>
      </Menu>
    </Content>
  )
}

export default OverlayProject;