import {createSlice} from "@reduxjs/toolkit";
import User from "../../models/user";
import {RootState} from "../reducer";
import Project from "../../models/project";

export const AUTH_STATE = {
  NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
  PENDING: "PENDING",
  AUTHENTICATED: "AUTHENTICATED",
  ERROR: "ERROR",
}

export interface SessionState {
  user: User | null;
  authState: string;
  projectSelected: Project | null;
}

const initialState: SessionState = {
  user: null,
  authState: AUTH_STATE.NOT_AUTHENTICATED,
  projectSelected: null,
}

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUser: (state: { user: any; }, action: { payload: any; }) => {
      state.user = action.payload;
    },
    logout: (state: { user: any; }) => {
      state.user = null;
    },
    setAuthStatus: (state, action) => {
      state.authState = action.payload;
    },
    resetAuthStatus: (state) => {
      state.authState = AUTH_STATE.NOT_AUTHENTICATED;
    },
    setProjectSelected: (state, action) => {
      state.projectSelected = action.payload;
      localStorage.setItem("projectSelected", JSON.stringify(action.payload));
    },
    resetProjectSelected: (state) => {
      state.projectSelected = null;
      localStorage.removeItem("projectSelected");
    }
  }
});

export const {
  setUser,
  logout,
  setAuthStatus,
  resetAuthStatus,
  setProjectSelected,
  resetProjectSelected
} = sessionSlice.actions;

export const selectUser = (state: RootState) => {
  if (state.session.user) {
    return state.session.user;
  }
};

export const selectAuthState = (state: RootState) => {
  return state.session.authState;
}

export const selectProjectSelected = (state: RootState) => {
  if (state.session.projectSelected) {
    return state.session.projectSelected;
  }
}

export default sessionSlice.reducer;