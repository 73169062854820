export default class Backup {
  id: string;
  name: string;
  projectId: string;
  size: number;
  type: number;
  uploadDate: Date;
  profileId: string;

  constructor({
    id = "",
    name = "",
    projectId = "",
    size = 0,
    type = 0,
    uploadDate = new Date(),
    profileId = "",
  }) {
    this.id = id;
    this.name = name;
    this.projectId = projectId;
    this.size = size;
    this.type = type;
    this.uploadDate = uploadDate;
    this.profileId = profileId;
  }
}