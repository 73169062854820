import BackupsLanding from "./BackupsLanding";
import {Route, Routes} from "react-router-dom";
import {ROUTES} from "../../utils/consts/router";
import BackupsCreate from "./BackupsCreate";
import BackupsContent from "./BackupsContent";

function Backups() {

  return (
    <div style={{
      width: "calc(100% - 250px)",
    }}>
      <Routes>
        <Route path="/" element={<BackupsLanding/>}/>
        <Route path={ROUTES.BACKUPS_CREATE_CHILD} element={<BackupsCreate/>}/>
        <Route path={ROUTES.BACKUPS_EDIT_CHILD} element={<BackupsContent/>}/>
      </Routes>
    </div>
  )
}

export default Backups;