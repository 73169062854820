import BreadCrumb from "../../components/global/BreadCrumb";

function Profile() {

  return (
    <div>
      <BreadCrumb text="Profil"/>
    </div>
  );
}

export default Profile;