import config from "../config";

export default class AgentServices {

  static AgentRename = (projectId: string, agentId: string, newName: string) => {
    return fetch(`${config.VITE_API_BACKUPS}/agents/${projectId}/${agentId}/settings/name`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        Name: newName
      })
    }).then((res) => res.json());
  }

  static AgentChangeURL = (projectId: string, agentId: string, newURL: string) => {
    return fetch(`${config.VITE_API_BACKUPS}/agents/${projectId}/${agentId}/settings/url`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        URL: newURL
      })
    }).then((res) => res.json());
  }
}