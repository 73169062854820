export default class BackupProfile {
  id: string;
  projectId: string;
  name: string;
  type: number;
  path: string;
  createdAt: string;

  constructor({
    id = "",
    projectId = "",
    name = "",
    type = 0,
    path = "",
    createdAt = "",
  }) {
    this.id = id;
    this.projectId = projectId;
    this.name = name;
    this.type = type;
    this.path = path;
    this.createdAt = createdAt;
  }
}

export enum BackupType {
  COMPLETED = 0,
  INCREMENT = 1,
}

export const BackupTypeMap = {
  [BackupType.COMPLETED]: "Complète",
  [BackupType.INCREMENT]: "Incrémentielle",
}