import {ReactNode, useCallback, useEffect, useState} from "react";
import User from "../models/user";
import {useDispatch, useSelector} from "react-redux";
import {isEmptyValue} from "../utils/Empty";
import {AUTH_STATE, selectAuthState, selectUser, setProjectSelected, setUser} from "../store/features/sessionSlice";
import Login from "../views/Login";
import LoadingPage from "../components/global/LoadingPage";
import UserServices from "../services/UserServices";
import {Button} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../utils/consts/router";

interface ProtectedPageProps {
  children: ReactNode;
  access: (options: { user: User | undefined }) => boolean;
}

function ProtectedPage({
  children,
  access,
}: ProtectedPageProps) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const authState = useSelector(selectAuthState);

  const [loginPage, setLoginPage] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    if (authState === AUTH_STATE.AUTHENTICATED && !isEmptyValue(user?.id)) {
      setLoadingPage(false);
      setLoginPage(false);
      return;
    }

    if (isEmptyValue(localStorage.getItem("token"))) {
      setLoginPage(true);
      setLoadingPage(false);
      return;
    }

    if (isEmptyValue(user?.id)) {
      if (authState === AUTH_STATE.NOT_AUTHENTICATED) {
        setLoadingPage(true);
        UserServices.getUser()
          .then((response) => {
            dispatch(setUser(response));
            const projectSelect = localStorage.getItem("projectSelected");
            if (!isEmptyValue(projectSelect)) {
              // @ts-ignore
              dispatch(setProjectSelected(JSON.parse(projectSelect)));
            }
          })
          .catch(() => {
            localStorage.removeItem("token");
            setLoadingPage(false);
            setLoginPage(true);
          })
          .finally(() => setLoadingPage(false));
      }
      return;
    }
    if (authState === AUTH_STATE.ERROR) {
      localStorage.removeItem("token");
      setLoginPage(true);
      setLoadingPage(false);
      return;
    }
    setLoadingPage(false);
  }, [user]);

  if (loginPage) return <Login/>;

  if (loadingPage) return <LoadingPage/>

  if (!access({user})) return <div>
    <h1>Vous n'avez pas accès à cette page</h1>
    <Button onClick={() => window.location.href = ROUTES.DEFAULT_SITE}>retour accueil</Button>
  </div>

  return (
    <div>
      {children}
    </div>
  )
}


export default ProtectedPage;