import {NavigationElement} from "../../../types/navigation";
import {ROUTES} from "../../../utils/consts/router";
import {RxDashboard} from "react-icons/rx";
import {styled} from "@chakra-ui/react";
import {HiOutlineClock} from "react-icons/hi";
import {FiSave} from "react-icons/fi";
import {useNavigate} from "react-router-dom";
import {VscProject} from "react-icons/vsc";
import {selectProjectSelected} from "../../../store/features/sessionSlice";
import {useSelector} from "react-redux";

const Navigation = styled("div", {
  baseStyle: {
    marginTop: "100px",
  }
})

const Element = styled("div", {
  baseStyle: {
    height: "40px",
    marginLeft: "30px",
    marginRight: "20px",
    display: "flex",
    color: "#fff",
    width: "auto",
    alignItems: "center",
    fontFamily: "Lato",
    fontWeight: "bold",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "25px",
    paddingBottom: "25px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5",
      borderRadius: "7px",
    },
    transition: "all 0.3 ease-in-out",
  }
})

const Icon = styled("div", {
  baseStyle: {
    margin: 5,
  }
})

const navigationElements: NavigationElement[] = [
  {
    title: "Dashboard",
    icon: <RxDashboard style={{width: 25, height: 25}}/>,
    path: ROUTES.HOME_CHILD,
  },
  {
    title: "Projet",
    icon: <VscProject style={{width: 25, height: 25}}/>,
    path: ROUTES.PROJECT_CHILD,
  },
  {
    title: "Sauvegardes",
    icon: <FiSave style={{width: 25, height: 25}}/>,
    path: ROUTES.BACKUPS_CHILD,
  },
  {
    title: "Programmation",
    icon: <HiOutlineClock style={{width: 25, height: 25}}/>,
    path: ROUTES.SCHEDULERS_CHILD,
  }
]

function OverlayNavigation() {

  const navigate = useNavigate();

  const projectSelected = useSelector(selectProjectSelected);

  return (
    <Navigation>
      {navigationElements.map((element, index) => {

        const current = element.path === ROUTES.HOME_CHILD ? (
          window.location.pathname === element.path
        ) : (
          window.location.pathname.includes(element.path)
        );

        if (element.path === ROUTES.PROJECT_CHILD && !projectSelected) return null;

        return (
          <Element key={index} onClick={() => navigate(element.path)}>
            <Icon style={
              current ? {color: "#FFB800"} : {color: "#939393"}
            }>
              {element.icon}
            </Icon>
            <div style={
              current ? {color: "#FFB800"} : {color: "#000000"}
            }>{element.title}</div>
          </Element>
        )
      })}
    </Navigation>
  )
}

export default OverlayNavigation;