import {useEffect, useState} from "react";
import BackupProfile from "../../models/backupProfile";
import BackupServices from "../../services/BackupServices";
import {selectProjectSelected} from "../../store/features/sessionSlice";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {CircularProgress, styled} from "@chakra-ui/react";
import BackupsProfilesTable from "../../components/backups/landing/BackupsProfilesTable";
import BackupsActions from "../../components/backups/landing/BackupsActions";
import ProjectNoFound from "../../assets/img/project_not_found.svg";
import BreadCrumb from "../../components/global/BreadCrumb";

const PageContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    width: "100%",
    height: "100%",
  }
})

const NotFoundText = styled("div", {
  baseStyle: {
    fontFamily: "Lato",
    fontWeight: "semibold",
    fontSize: "20px",
    color: "#000000",
    marginTop: "20px",
  }
})

const NotFoundTextLow = styled("div", {
  baseStyle: {
    fontFamily: "Lato",
    fontWeight: "regular",
    fontSize: "18px",
    color: "#000000",
  }
})

function BackupsLanding() {

  const projectSelected = useSelector(selectProjectSelected);

  const [backups, setBackups] = useState<BackupProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!projectSelected) return;

    setLoading(true);
    BackupServices.getProfiles(projectSelected?.id || "").then((data) => {
      setBackups(data);
    }).finally(() => setLoading(false));
  }, [projectSelected]);

  return (
    <PageContent>
      <BreadCrumb text="Dashboard - Backups"/>
      {projectSelected ? (
        loading ? (
          <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <CircularProgress isIndeterminate color="orange"/>
          </div>
        ) : (
          <>
            <BackupsActions/>
            <BackupsProfilesTable backups={backups}/>
          </>
        )
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={ProjectNoFound} alt={"project not found"} width={600}/>
          <NotFoundText>
            Aucun projet n'est sélectionné.
          </NotFoundText>
          <NotFoundTextLow>
            Veuillez sélectionner un projet dans la barre de navigation.
          </NotFoundTextLow>
        </div>
      )
      }
    </PageContent>
  )

}

export default BackupsLanding;