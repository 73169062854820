import User from "../../models/user";
import {Input, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast} from "@chakra-ui/react";
import Agent from "../../models/agent";
import {useSelector} from "react-redux";
import {selectProjectSelected} from "../../store/features/sessionSlice";
import AgentServices from "../../services/AgentServices";

interface ProjectTableAgentProps {
  agents: Agent[];
}

function ProjectTableAgent({
  agents
}: ProjectTableAgentProps) {

  const toast = useToast();

  const selectedProject = useSelector(selectProjectSelected);

  const handleChangeName = (agentId: string, name: string) => {
    AgentServices.AgentRename(selectedProject?.id || "", agentId, name)
      .then(() => {
        toast({
          title: "Agent renommé",
          description: "L'agent a bien été renommé",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      });
  }

  const handleChangeUrl = (agentId: string, url: string) => {
    AgentServices.AgentChangeURL(selectedProject?.id || "", agentId, url)
      .then(() => {
        toast({
          title: "URL de l'agent modifiée",
          description: "L'URL de l'agent a bien été modifiée",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      });
  }

  return (
    <TableContainer backgroundColor="white" borderRadius={5} style={{
      width: "100%",
      marginTop: "20px",
    }}>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>Identifiant</Th>
            <Th>Nom</Th>
            <Th>URL</Th>
            <Th>Dernier ping</Th>
            <Th>Statut</Th>
          </Tr>
        </Thead>
        <Tbody>
          {agents.map((agent) => {

            let date: string = new Date(agent.lastHeartbeat).toLocaleDateString();

            if (date.toString() === "Invalid Date") {
              date = "Aucune connexion"
            }

            return (
              <Tr key={JSON.stringify(agent)}>
                <Td>{agent.id}</Td>
                <Td>
                  <Input
                    backgroundColor={"rgba(246,246,246,0.78)"}
                    defaultValue={agent.name}
                    onBlur={(e) => handleChangeName(agent.id, e.target.value)}
                  />
                </Td>
                <Td>
                  <Input
                    backgroundColor={"rgba(246,246,246,0.78)"}
                    defaultValue={agent.url}
                    onBlur={(e) => handleChangeUrl(agent.id, e.target.value)}
                  />
                </Td>
                {agent.online ? (<Td>{date}</Td>) : (
                  <Td>Indéfini</Td>
                )}
                {agent.online ? (
                  <Td style={{color: "green"}}>En ligne</Td>
                ) : (
                  <Td style={{color: "red"}}>Hors ligne</Td>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default ProjectTableAgent