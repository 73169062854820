import config from "../config";

export default class UserServices {
  static getUser = () => {
    return fetch(`${config.VITE_API_BACKOFFICE}/auth/get`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error("Error");
    })
      .catch((err) => {
        localStorage.removeItem("token");
        err.json();
      });
  };

}