import {Button, Input, InputGroup, InputLeftElement, styled, useToast} from "@chakra-ui/react";
import {isEmptyValue} from "../utils/Empty";
import AuthServices from "../services/AuthServices";
import UserServices from "../services/UserServices";
import {AUTH_STATE, setAuthStatus, setUser} from "../store/features/sessionSlice";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {MdAlternateEmail, MdPassword} from "react-icons/md";
import {FiArrowRight} from "react-icons/fi";
import Logo from "../assets/img/tolnkee_logo_long.png";

const Page = styled("div", {
  baseStyle: {
    display: "flex",
    backgroundColor: "#F5F5F5",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  }
})

const Content = styled("div", {
  baseStyle: {
    width: "400px",
    height: "450px",
    backgroundColor: "#FFFFFF",
    borderRadius: "7px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    shadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  }
})

const Header = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
  }
})

const SubHeader = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    marginBottom: "30px",
  }
})

const Text = styled("div", {
  baseStyle: {
    fontFamily: "Lato",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#000000",
  }
})

const InputGroupStyled = styled(InputGroup, {
  baseStyle: {
    width: "300px",
    height: "50px",
    marginTop: "10px",
  }
})

function Login() {
  const toast = useToast();

  const dispatch = useDispatch();

  const [email, setMail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const errorToast = (description: string) => {
    toast({
      title: "Error",
      description,
      status: "error",
      duration: 2000,
    })
  }

  const errorMailMessage = "Veuillez renseigner votre adresse e-email";
  const errorPasswordMessage = "Veuillez renseigner votre mot de passe";

  const handleLogin = () => {

    if (isEmptyValue(email)) {
      setErrorEmail(true)
      errorToast(errorMailMessage)
      return;
    }

    if (isEmptyValue(password)) {
      setErrorPassword(true);
      errorToast(errorPasswordMessage);
      return;
    }

    setLoading(true);
    AuthServices.signIn(email, password).then((response) => {
      localStorage.setItem("token", response.token);
      UserServices.getUser().then((response) => dispatch(setUser(response)))
      dispatch(setAuthStatus(AUTH_STATE.AUTHENTICATED));
      return;
    })
      .catch(() => dispatch(setAuthStatus(AUTH_STATE.ERROR)))
      .finally(() => setLoading(false));
  }
  return (
    <Page>
      <Content>
        <Header>
          <img alt="Logo" src={Logo} width={170}/>
        </Header>
        <SubHeader>
          <Text>Connectez-vous à votre compte</Text>
        </SubHeader>

        <InputGroupStyled>
          <InputLeftElement
            pointerEvents='none'
            children={<MdAlternateEmail color='gray.300'/>}
          />
          <Input
            focusBorderColor='#FFB800'
            type='email'
            placeholder='Adresse mail'
            value={email}
            onChange={(e) => {
              setMail(e.target.value);
              setErrorEmail(false);
            }}
            isInvalid={errorEmail}
          />
        </InputGroupStyled>

        <InputGroupStyled>
          <InputLeftElement
            pointerEvents='none'
            children={<MdPassword color='gray.300'/>}
          />
          <Input
            focusBorderColor='#FFB800'
            type='password'
            placeholder='Mot de passe'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorPassword(false);
            }}
            isInvalid={errorPassword}
          />
        </InputGroupStyled>

        <Button
          isLoading={loading}
          loadingText="Connexion"
          backgroundColor="#FFB800"
          variant="solid"
          width="200px"
          height="45px"
          marginTop="20px"
          rightIcon={<FiArrowRight/>}
          onClick={handleLogin}>
          Connexion
        </Button>
      </Content>
    </Page>
  );
}

export default Login;