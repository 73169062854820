import {Route, Routes} from "react-router-dom";
import ProjectLanding from "./ProjectLanding";

function Project() {

  return (
    <div style={{
      width: "calc(100% - 250px)",
    }}>
      <Routes>
        <Route path="/" element={<ProjectLanding/>}/>
      </Routes>
    </div>
  )
}

export default Project