import {CircularProgress, Input, styled, useToast} from "@chakra-ui/react";
import {selectProjectSelected, setProjectSelected} from "../../store/features/sessionSlice";
import {useDispatch, useSelector} from "react-redux";
import BreadCrumb from "../../components/global/BreadCrumb";
import Project from "../../models/project";
import {useEffect, useState} from "react";
import ProjectServices from "../../services/ProjectServices";
import ProjectTableMembers from "../../components/project/ProjectTableMembers";
import ProjectTableAgent from "../../components/project/ProjectTableAgent";

const Content = styled("div", {
  baseStyle: {
    padding: "20px",
  }
})

const Element = styled("div", {
  baseStyle: {
    padding: "2px",
    display: "flex",
    flexDirection: "column",
  }
})

const ElementTitle = styled("div", {
  baseStyle: {
    fontFamily: "Lato",
    fontWeight: "semibold",
    fontSize: "18px",
    color: "#000000",
  }
})

const ElementContent = styled(Input, {
  baseStyle: {
    fontFamily: "Lato",
    fontWeight: "regular",
    fontSize: "18px",
    color: "#000000",
    maxWidth: "350px",
    backgroundColor: "#ffffff",
  },
})

function ProjectLanding() {

  const toast = useToast();
  const dispatch = useDispatch();
  const projectSelected = useSelector(selectProjectSelected);
  const [fullProject, setFullProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (projectSelected?.id !== null) {
      setLoading(true);
      ProjectServices.getProject(projectSelected?.id || "").then(
        (response) => {
          setFullProject(response);
        }).finally(() => setLoading(false));
    }
  }, [])

  const handleChangeName = (name: string) => {
    if (name.length < 3) {
      toast({
        title: "Project rename failed",
        description: "The project name must be at least 3 characters long",
        status: "error",
        duration: 5000,
      })
      return;
    }

    if (name.length > 30) {
      toast({
        title: "Project rename failed",
        description: "The project name must be at most 30 characters long",
        status: "error",
        duration: 5000,
      })
      return;
    }

    if (name === projectSelected?.name) {
      return;
    }

    ProjectServices.ProjectRename(projectSelected?.id || "", name).then(
      () => {
        toast({
          title: "Project renamed",
          description: "The project has been renamed",
          status: "success",
          duration: 5000,
        })
        ProjectServices.getProject(projectSelected?.id || "").then(
          (response) => {
            setFullProject(response);
            dispatch(setProjectSelected(response));
          });
      }).catch((error) => {
      toast({
        title: "Project rename failed",
        description: error.message,
        status: "error",
        duration: 5000,
      })
    })
  }

  const handleChangeColor = (color: string) => {

    if (color === projectSelected?.color) {
      return;
    }

    if (color.length !== 3 && color.length !== 6 && color.length !== 7) {
      toast({
        title: "Project color change failed",
        description: "The project color must be a valid hex color",
        status: "error",
        duration: 5000,
      })
      return;
    }

    if (color.length === 7 && color[0] !== "#") {
      toast({
        title: "Project color change failed",
        description: "The project color must be a valid hex color",
        status: "error",
        duration: 5000,
      })
      return;
    }

    if (color[0] === "#") {
      color = color.substring(1);
    }

    ProjectServices.ProjectChangeColor(projectSelected?.id || "", color).then(
      () => {
        toast({
          title: "Project color changed",
          description: "The project color has been changed",
          status: "success",
          duration: 5000,
        })
        ProjectServices.getProject(projectSelected?.id || "").then(
          (response) => {
            setFullProject(response);
            dispatch(setProjectSelected(response));
          });
      }).catch((error) => {
      toast({
        title: "Project color change failed",
        description: error.message,
        status: "error",
        duration: 5000,
      })
    })
  }

  let Members;

  if (fullProject?.members) {
    if (fullProject?.members.length > 0) {
      if (typeof fullProject?.members[0] === "string") {
        Members = "No members"
      } else {
        // @ts-ignore
        Members = (<ProjectTableMembers members={fullProject?.members}/>)
      }
    }
  }

  return (
    <Content>
      <BreadCrumb text="Projet - Landing"/>
      {loading ? (
        <CircularProgress isIndeterminate color="green.300"/>
      ) : (
        <div>
          <div style={{paddingTop: "10px"}}/>
          <Element>
            <ElementTitle>Identifiant</ElementTitle>
            <ElementContent
              value={fullProject?.id}
              isReadOnly
              backgroundColor={"rgba(255,255,255,0.32)"}
              cursor={"not-allowed"}
            />
          </Element>
          <div style={{paddingTop: "10px"}}/>
          <div style={{
            display: "flex",
          }}>
            <Element>
              <ElementTitle>Nom</ElementTitle>
              <ElementContent
                defaultValue={fullProject?.name}
                onBlur={(e: { target: { value: string; }; }) => handleChangeName(e.target.value)}
              />
            </Element>
            <div style={{width: "10px"}}/>
            <Element>
              <ElementTitle>Couleur</ElementTitle>
              <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
                <div style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: `#${fullProject?.color}`,
                  borderRadius: "5px",
                  paddingRight: "10px",
                  marginRight: "10px",
                }}/>
                <ElementContent
                  defaultValue={fullProject?.color}
                  onBlur={(e: { target: { value: string; }; }) => handleChangeColor(e.target.value)}
                />
              </div>
            </Element>
          </div>
          <div style={{paddingTop: "10px"}}/>
          <Element>
            <ElementTitle>Membres</ElementTitle>
            {Members}
          </Element>
          <Element>
            <ElementTitle>Agents</ElementTitle>
            <ProjectTableAgent agents={fullProject?.targets || []}/>
          </Element>
        </div>
      )}
    </Content>
  )
}

export default ProjectLanding