import config from "../config";

export default class ProjectServices {

  static getProjects = () => {
    return fetch(`${config.VITE_API_BACKOFFICE}/projects/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  }

  static getProject = (id: string) => {
    return fetch(`${config.VITE_API_BACKUPS}/projects/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  }

  static ProjectRename = (id: string, newName: string) => {
    return fetch(`${config.VITE_API_BACKOFFICE}/projects/${id}/settings/name`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        Name: newName
      })
    }).then((res) => res.json());
  }

  static ProjectChangeColor = (id: string, color: string) => {
    return fetch(`${config.VITE_API_BACKOFFICE}/projects/${id}/settings/color`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        Color: color
      })
    }).then((res) => res.json());
  }

}
