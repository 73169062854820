import {
  IconButton, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList,
  Popover,
  PopoverArrow, PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  styled,
  useDisclosure
} from "@chakra-ui/react";
import ProfileImage from "../../assets/img/profile.png"
import Image from "../global/Image";
import {useDispatch, useSelector} from "react-redux";
import {logout, selectUser} from "../../store/features/sessionSlice";
import {ROUTES} from "../../utils/consts/router";
import {Form, useNavigate} from "react-router-dom";
import {useRef} from "react";


const Profile = styled("div", {
  baseStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  }
})

const Head = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    width: "50px",
    cursor: "pointer",
    backgroundColor: "#F5F5F5",
    borderRadius: "7px",
  }
})

const ProfileName = styled("div", {
  baseStyle: {
    marginLeft: "10px",
    fontFamily: "Lato",
    fontWeight: "semibold",
    fontSize: "16px",
    color: "#000000",
  }
})

function OverlayProfile() {

  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("projectSelected");
    dispatch(logout());
    navigate(ROUTES.HOME_CHILD);
  }

  return (
    <Menu>
      <MenuButton style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}>
        <Profile>
          <Head>
            <Image src={ProfileImage} alt="Profile" width={70} borderRadius={7}/>
          </Head>
          <ProfileName>{`${user?.lastName} ${user?.firstName}`}</ProfileName>
        </Profile>
      </MenuButton>
      <MenuList>
        <MenuGroup title='Profil'>
          <MenuItem onClick={() => navigate(ROUTES.PROFILE)}>Mon compte</MenuItem>
          <MenuItem onClick={handleSignOut}>Déconnexion</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export default OverlayProfile;