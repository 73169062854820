import BreadCrumb from "../../components/global/BreadCrumb";

function Schedulers() {

  return (
    <div>
      <BreadCrumb text="Schedulers"/>
    </div>
  )
}

export default Schedulers;