import {Button, Input, InputGroup, InputLeftAddon, Select, styled, useToast} from "@chakra-ui/react";
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {BackupType, BackupTypeMap} from "../../models/backupProfile";
import BackupServices from "../../services/BackupServices";
import {useSelector} from "react-redux";
import {selectProjectSelected} from "../../store/features/sessionSlice";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/consts/router";
import BreadCrumb from "../../components/global/BreadCrumb";

const PageContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  }
})

const Form = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "10px",
  }
})

const FormItem = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "5px",
    paddingBottom: "5px",
  }
})

const FormButton = styled("div", {
  baseStyle: {
    paddingTop: "5px",
  }
})

function BackupsCreate() {

  const projectSelected = useSelector(selectProjectSelected);

  const toast = useToast();
  const navigate = useNavigate();

  let schema = yup.object().shape({
    name: yup
      .string()
      .min(3, "Le nom doit contenir au moins 3 caractères")
      .max(50, "Le nom doit contenir au plus 50 caractères")
      .required("Le nom est requis"),
    type: yup
      .number()
      .required("Le type est requis"),
    path: yup
      .string()
      .required("Le chemin est requis"),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCreation = ({
    name,
    type,
    path,
  }: { name: string, type: number, path: string }) => {
    BackupServices.addProfile(projectSelected?.id || "", name, type, path)
      .then(() => {
        toast({
          title: "Profil créé",
          description: "Le profil a bien été créé",
          status: "success",
          duration: 2000,
        });
        navigate(ROUTES.BACKUPS_CHILD);
      });
  }

  return (
    <PageContent>
      <BreadCrumb text="Dashboard - Backups - Création"/>

      <Form>
        <FormItem>
          <InputGroup width={365}>
            <InputLeftAddon children='ABC'/>
            <Input
              type={"text"}
              placeholder="Nom du backup"
              {...register("name")}
              backgroundColor={"white"}
              width="300px"
            />
          </InputGroup>
        </FormItem>

        <FormItem>
          <Select
            placeholder="Type du backup"
            {...register("type")}
            backgroundColor={"white"}
            width={300}
          >
            <option value={0}>{BackupTypeMap[BackupType.COMPLETED]}</option>
            <option value={1}>{BackupTypeMap[BackupType.INCREMENT]}</option>
          </Select>
        </FormItem>

        <FormItem>
          <Input
            placeholder="Chemin du backup"
            {...register("path")}
            backgroundColor={"white"}
            width={300}
          />
        </FormItem>
      </Form>

      <FormButton>
        <Button
          colorScheme="blue"
          type="submit"
          // @ts-ignore
          onClick={() => handleSubmit(data => handleCreation(data))()}
          width={150}
        >
          Créer
        </Button>
      </FormButton>
    </PageContent>
  )
}

export default BackupsCreate;