import ProtectedPageTemplate from "../ProtectedPageTemplate";
import {Route, Routes} from "react-router-dom";
import Backups from "../backups/Backups";
import Schedulers from "../schedulers/Schedulers";
import {ROUTES} from "../../utils/consts/router";
import Landing from "./Landing";
import Profile from "../profile/Profile";
import Project from "../project/Project";

function Home() {

  return(
    <ProtectedPageTemplate>
      <Routes>
        <Route path={ROUTES.HOME_CHILD} element={<Landing/>}/>
        <Route path={ROUTES.PROJECT} element={<Project/>}/>
        <Route path={ROUTES.BACKUPS} element={<Backups/>}/>
        <Route path={ROUTES.SCHEDULERS_CHILD} element={<Schedulers/>}/>
        <Route path={ROUTES.PROFILE} element={<Profile/>}/>
      </Routes>
    </ProtectedPageTemplate>
  )
}

export default Home;