import {Button, styled, useToast} from "@chakra-ui/react";
import {VscDebugStart} from "react-icons/vsc";
import BackupServices from "../../../services/BackupServices";
import BackupProfile from "../../../models/backupProfile";
import Backup from "../../../models/backup";
import {useState} from "react";
import {MdDeleteOutline} from "react-icons/md";

const Content = styled("div", {
  baseStyle: {
    marginTop: "30px",
    marginBottom: "-35px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  }
})

interface globalProfileInterface {
  profile: BackupProfile,
  backups: Backup[],
}

interface BackupsContentActionsProps {
  globalProfile: globalProfileInterface | null;
  setGlobalProfile: (value: globalProfileInterface | null) => void;
}

function BackupsContentActions({
  globalProfile,
  setGlobalProfile
}: BackupsContentActionsProps) {

  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleLaunchBackup = () => {
    setLoading(true);

    setGlobalProfile({
      profile: globalProfile?.profile || new BackupProfile({}),
      backups: [
        ...globalProfile?.backups || [],
        new Backup({
          id: "...",
          name: "Création en cours...",
          projectId: globalProfile?.profile.projectId || "",
          profileId: globalProfile?.profile.id || "",
          size: 0,
          type: 0,
        })
      ]
    });

    BackupServices.launchProfileBackup(globalProfile?.profile.projectId || "", globalProfile?.profile.id || "").then(
      (response) => {
        toast({
          title: "Backup lancé",
          description: "Le backup a été lancé avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        BackupServices.getProfile(globalProfile?.profile.projectId || "", globalProfile?.profile.id || "").then(
          (data) => {
            setGlobalProfile(data);
          });
      }
    ).finally(() => setLoading(false));
  }

  const handleDeleteProfile = () => {
    setLoading(true);
    BackupServices.deleteProfile(globalProfile?.profile.projectId || "", globalProfile?.profile.id || "").then(
      (response) => {
        toast({
          title: "Profil supprimé",
          description: "Le profil a été supprimé avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    ).catch((error) => {
      toast({
        title: "Erreur lors de la suppression du profil",
        description: "Une erreur est survenue lors de la suppression du profil. " + error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    })
      .finally(() => setLoading(false));
  }

  return (
    <Content>
      <Button
        colorScheme="blue"
        leftIcon={<VscDebugStart/>}
        onClick={() => handleLaunchBackup()}
        isLoading={loading}
      >
        Lancer une backup
      </Button>
      <div style={{width: "10px"}}/>
      <Button
        colorScheme="red"
        leftIcon={<MdDeleteOutline/>}
        onClick={() => handleDeleteProfile()}
        isLoading={loading}
      >
        Supprimer le profil
      </Button>
    </Content>
  )
}

export default BackupsContentActions;