import BreadCrumb from "../../components/global/BreadCrumb";

function Landing() {

  return (
    <div style={{
      width: "calc(100% - 250px)",
      padding: "20px",
    }}>
      <BreadCrumb text="Landing"/>
    </div>
  );
}

export default Landing;