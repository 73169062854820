export const ROUTES = {
  HOME: '/*',
  HOME_CHILD: '/',
  BACKUPS: '/backups/*',
  BACKUPS_CHILD: '/backups',
  BACKUPS_CREATE: '/backups/create',
  BACKUPS_CREATE_CHILD: '/create',
  BACKUPS_EDIT: '/backups/edit/:id',
  BACKUPS_EDIT_CHILD: '/edit/:id',
  SCHEDULERS: '/schedulers/*',
  SCHEDULERS_CHILD: '/schedulers',
  SCHEDULERS_CREATE: '/schedulers/create',
  SCHEDULERS_EDIT: '/schedulers/edit/:id',
  PROJECT: '/project/*',
  PROJECT_CHILD: '/project',
  PROJECT_CREATE: '/project/create',
  PROJECT_EDIT: '/project/edit/:id',
  PROFILE: '/profile',
  ERROR_404: "*/*",
  DEFAULT_SITE: "https://tolnkee.com"
}

export const AccessToBackups = (id: string) => ROUTES.BACKUPS_EDIT.replace(':id', id);