import {styled} from "@chakra-ui/react";

const Text = styled("div", {
  baseStyle: {
    fontFamily: "Lato",
    fontWeight: "normal",
    fontSize: "18px",
    color: "#000000",
    textTransform: "inherit",
  }
})

interface BreadCrumbProps {
  text: string;
}

function BreadCrumb({
  text
}: BreadCrumbProps) {

  return (
    <Text>
      {text}
    </Text>
  )
}

export default BreadCrumb