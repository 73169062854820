import {Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import BackupProfile, {BackupType, BackupTypeMap} from "../../../models/backupProfile";
import {FiArrowRight} from "react-icons/fi";
import {useNavigate} from "react-router-dom";
import {AccessToBackups} from "../../../utils/consts/router";

interface BackupsTableProps {
  backups: BackupProfile[];
}

function BackupsProfilesTable({
  backups
}: BackupsTableProps = {backups: []}) {

  const navigate = useNavigate();

  return (
    <TableContainer backgroundColor="white" borderRadius={5} style={{
      width: "100%",
    }}>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Nom</Th>
            <Th>Type</Th>
            <Th>Chemin</Th>
            <Th isNumeric>Accéder</Th>
          </Tr>
        </Thead>
        <Tbody>
          {backups.map((backup) => (
            <Tr key={JSON.stringify(backup)}>
              <Td>{backup.id}</Td>
              <Td>{backup.name}</Td>
              <Td>{BackupTypeMap[backup.type as BackupType]}</Td>
              <Td>{backup.path}</Td>
              <Td isNumeric>
                <Button
                  h={8}
                  w={8}
                  colorScheme='blue'
                  onClick={() => navigate(AccessToBackups(backup.id))}
                >
                  <FiArrowRight style={{
                    width: 16,
                    height: 16,
                    margin: -10,
                  }}/>
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default BackupsProfilesTable;