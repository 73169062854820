import User from "../../models/user";
import {Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";

interface ProjectTableMembersProps {
  members: User[];
}

function ProjectTableMembers({
  members
}: ProjectTableMembersProps) {

  return (
    <TableContainer backgroundColor="white" borderRadius={5} style={{
      width: "100%",
      marginTop: "20px",
    }}>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>Nom</Th>
            <Th>Prénom</Th>
            <Th>Email</Th>
          </Tr>
        </Thead>
        <Tbody>
          {members.map((member) => {
            return (
              <Tr key={JSON.stringify(member)}>
                <Td>{member.lastName}</Td>
                <Td>{member.firstName}</Td>
                <Td>{member.email}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default ProjectTableMembers