import config from "../config";

export default class BackupServices {

  static getProfiles = (project: string) => {
    return fetch(`${config.VITE_API_BACKUPS}/profiles/${project}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  }

  static getProfile = (project: string, id: string) => {
    return fetch(`${config.VITE_API_BACKUPS}/profiles/${project}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  }

  static launchProfileBackup = (project: string, id: string) => {
    return fetch(`${config.VITE_API_BACKUPS}/backup/perform`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        projectId: project,
        profileId: id
      })
    }).then((res) => res.json());
  }

  static addProfile = (projectId: string, name: string, type: number, path: string) => {
    return fetch(`${config.VITE_API_BACKUPS}/profiles/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        projectId,
        name,
        type,
        path,
      }),
    }).then((res) => res.json());
  }

  static deleteProfile = (projectId: string, id: string) => {
    return fetch(`${config.VITE_API_BACKUPS}/profiles/${projectId}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error("Error");
    })
      .catch((err) => {
        return err.json();
      });
  }

  static getBackups = (project: string) => {
    return fetch(`${config.VITE_API_BACKUPS}/backup/${project}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  }

}
