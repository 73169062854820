import BreadCrumb from "../../components/global/BreadCrumb";
import {CircularProgress, styled} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import Backup from "../../models/backup";
import {useEffect, useState} from "react";
import {selectProjectSelected} from "../../store/features/sessionSlice";
import {useSelector} from "react-redux";
import BackupServices from "../../services/BackupServices";
import BackupsContentTable from "../../components/backups/content/BackupsContentTable";
import BackupProfile from "../../models/backupProfile";
import BackupsContentActions from "../../components/backups/content/BackupsContentActions";

const PageContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    width: "100%",
    height: "100%",
  }
})

const PageTitle = styled("div", {
  baseStyle: {
    fontFamily: "Lato",
    fontWeight: "semibold",
    fontSize: "20px",
    color: "#000000",
  }
})

const PageElements = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    justifyContent: "space-between",
  }
})

const Element = styled("div", {
  baseStyle: {
    padding: "2px",
    display: "flex",
    flexDirection: "column",
  }
})

const ElementTitle = styled("div", {
  baseStyle: {
    fontFamily: "Lato",
    fontWeight: "semibold",
    fontSize: "18px",
    color: "#000000",
  }
})

const ElementContent = styled("div", {
  baseStyle: {
    fontFamily: "Lato",
    fontWeight: "regular",
    fontSize: "18px",
    color: "#000000",
  }
})

function BackupsContent() {

  const selectedProject = useSelector(selectProjectSelected);
  const backupId = useParams<{ id: string }>().id;

  const [globalProfile, setGlobalProfile] = useState<{
    profile: BackupProfile,
    backups: Backup[],
  } | null>(null);
  const [filteredBackups, setFilteredBackups] = useState<Backup[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    BackupServices.getProfile(selectedProject?.id || "", backupId || "").then((data) => {
      setGlobalProfile(data);
    }).finally(() => setLoading(false));
  }, [backupId]);

  useEffect(() => {
    setFilteredBackups(globalProfile?.backups.filter((backup) => backup.projectId === selectedProject?.id) || []);
  }, [globalProfile]);

  return (
    <PageContent>
      <BreadCrumb text={`Dashboard - Backups - Information n°${backupId}`}/>
      {loading ? (
        <div style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <CircularProgress isIndeterminate color="orange"/>
        </div>
      ) : (
        <div>
          <PageTitle>Informations du backup</PageTitle>
          <PageElements>
            <Element>
              <ElementTitle>Identifiant</ElementTitle>
              <ElementContent>{globalProfile?.profile.id}</ElementContent>
            </Element>
            <Element>
              <ElementTitle>Nom</ElementTitle>
              <ElementContent>{globalProfile?.profile.name}</ElementContent>
            </Element>
            <Element>
              <ElementTitle>Projet</ElementTitle>
              <ElementContent>{selectedProject?.name}</ElementContent>
            </Element>
            <Element>
              <ElementTitle>Créé le</ElementTitle>
              <ElementContent>{globalProfile?.profile.createdAt}</ElementContent>
            </Element>
            <Element>
              <ElementTitle>Chemin</ElementTitle>
              <ElementContent>{globalProfile?.profile.path}</ElementContent>
            </Element>
          </PageElements>
          <BackupsContentActions globalProfile={globalProfile} setGlobalProfile={setGlobalProfile}/>
          <PageTitle>Liste des backups</PageTitle>
          <BackupsContentTable backups={filteredBackups}/>
        </div>
      )}
    </PageContent>
  )
}

export default BackupsContent;