import {Button, styled} from "@chakra-ui/react";
import {BiAddToQueue} from "react-icons/bi";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../utils/consts/router";

const Content = styled("div", {
  baseStyle: {
    marginTop: "30px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  }
})

function BackupsActions() {

  const navigate = useNavigate();

  return (
    <Content>
      <Button
        colorScheme="blue"
        leftIcon={<BiAddToQueue/>}
        onClick={() => navigate(ROUTES.BACKUPS_CREATE)}
      >
        Créer un backup
      </Button>
    </Content>
  )
}

export default BackupsActions;