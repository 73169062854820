import {Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {BackupType, BackupTypeMap} from "../../../models/backupProfile";
import {useNavigate} from "react-router-dom";
import {AccessToBackups} from "../../../utils/consts/router";
import Backup from "../../../models/backup";
import {AiOutlineCloudDownload} from "react-icons/ai";

interface BackupsTableProps {
  backups: Backup[];
}

function BackupsContentTable({
  backups
}: BackupsTableProps = {backups: []}) {

  const navigate = useNavigate();

  return (
    <TableContainer backgroundColor="white" borderRadius={5} style={{
      width: "100%",
      marginTop: "20px",
    }}>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Nom</Th>
            <Th>Type</Th>
            <Th>Taille</Th>
            <Th isNumeric>Télécharger</Th>
          </Tr>
        </Thead>
        <Tbody>
          {backups.map((backup) => {

            const isInvalid = backup.name === "Création en cours...";

            return (
              <Tr key={JSON.stringify(backup)}>
                <Td style={{color: isInvalid ? "gray" : "black"}}>{backup.id}</Td>
                <Td style={{color: isInvalid ? "gray" : "black"}}>{backup.name}</Td>
                <Td style={{color: isInvalid ? "gray" : "black"}}>{BackupTypeMap[backup.type as BackupType]}</Td>
                <Td style={{color: isInvalid ? "gray" : "black"}}>{backup.size} Bytes</Td>
                <Td style={{color: isInvalid ? "gray" : "black"}} isNumeric>
                  <Button
                    h={8}
                    w={8}
                    colorScheme='orange'
                    onClick={() => navigate(AccessToBackups(backup.id))}
                  >
                    <AiOutlineCloudDownload style={{
                      width: 16,
                      height: 16,
                      margin: -10,
                    }}/>
                  </Button>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default BackupsContentTable;